<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo" :to="{ name: 'login' }">
        <artos-logo />

        <h2 class="brand-text text-primary ml-1">
          ARTOS
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Illustration of Signup"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ this.$t('pages.signup.headerCardTitle') }}
          </b-card-title>

          <!-- form -->
          <validation-observer ref="formRef">
            <b-form class="auth-register-form mt-2">
              <!-- username -->
              <b-form-group
                :label="this.$t('pages.signup.singular.userName')"
                label-for="username"
              >
                <validation-provider
                  #default="{ errors }"
                  mode="passive"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    ref="refFocus"
                    v-model="form.userName"
                    name="username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="John Doe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                :label="this.$t('pages.signup.singular.email')"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="form.userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                :label="this.$t('pages.signup.singular.password')"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="form.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                :label="this.$t('pages.signup.singular.passwordConfirmation')"
                label-for="password-confirmation"
              >
                <validation-provider
                  #default="{ errors }"
                  name="confirm"
                  rules="required|confirm:@password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password-confirmation"
                      v-model="passwordConfirmation"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="isAccepted"
                  name="checkbox-1"
                >
                  {{ this.$t('pages.signup.iAgree') }}
                  <b-link>{{ this.$t('pages.signup.terms') }}</b-link>
                </b-form-checkbox>
                <small class="text-danger">{{ termsValidationMessages }}</small>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="isLoading"
                @click.prevent="validationForm"
              >
                <b-spinner
                  small
                  type="grow"
                  v-if="isLoading"
                />
                {{ this.$t('pages.signup.actions.signUp') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-1 mb-0">
            <span>{{ this.$t('pages.signup.alreadyHaveAccount') }}</span>
            <br>
            <b-link :to="{name:'login'}">
              <span>&nbsp;{{ this.$t('pages.signup.actions.signInInstead') }}</span>
            </b-link>
          </p>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import ArtosLogo from '@/components/logo/Logo.vue'
import {
  BRow, BSpinner, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle
} from 'bootstrap-vue'
import { required, email } from '@validations'
// import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import router from '@/router'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ref, onMounted, computed } from '@vue/composition-api'
import useHttp from '@/comp-functions/useHttp'
import useLocalization from '@/comp-functions/utils/useLocalization'

extend('confirm', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: 'Password didn\'t match!'
})

export default {
  components: {
    ArtosLogo,
    BRow,
    BSpinner,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    // BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver
  },
  // mixins: [togglePasswordVisibility],
  setup () {
    const { $post } = useHttp()
    const { $t } = useLocalization()

    const passwordFieldType = ref('password')
    const togglePasswordVisibility = () => {
      passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password'
    }

    const formRef = ref(null)
    const refFocus = ref(null)
    const isAccepted = ref(false)
    const termsValidationMessages = ref('')
    const isLoading = ref(false)
    const passwordConfirmation = ref('')
    const form = ref({
      userName: '',
      userEmail: '',
      password: ''
    })
    
    const sideImg = ref(require('@/assets/images/pages/register-v2.svg'))
    
    const passwordToggleIcon = computed(() => (passwordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))
    const imgUrl = computed(() => {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        sideImg.value = require('@/assets/images/pages/register-v2-dark.svg')
        return sideImg.value
      }
      return sideImg.value
    })
    

    const validationForm = async () => {
      const validated = await formRef.value.validate()
      if (validated) {
        if (!isAccepted.value) {
          termsValidationMessages.value = $t('validations.messages.agreement')
        } else {
          isLoading.value = true
          $post({
            url: 'auth/signup',
            data: form.value
          })
            .then(() => {
              isLoading.value = false
              router.push({ name: 'membership-user-signup-success', params: { form: form.value } })
            })
            .catch(() => {
              isLoading.value = false
            })
        }
      }
    }

    onMounted(() => {
      refFocus.value.focus()
    })

    return {
      formRef,
      refFocus,
      isLoading,
      isAccepted,
      termsValidationMessages,
      passwordConfirmation,
      form,
      sideImg,
      passwordFieldType,
      togglePasswordVisibility,
      passwordToggleIcon,
      imgUrl,
      validationForm,
      // validation
      required,
      email
    }
  }
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
