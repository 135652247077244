var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo",attrs:{"to":{ name: 'login' }}},[_c('artos-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" ARTOS ")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Illustration of Signup"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(this.$t('pages.signup.headerCardTitle'))+" ")]),_c('validation-observer',{ref:"formRef"},[_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":this.$t('pages.signup.singular.userName'),"label-for":"username"}},[_c('validation-provider',{attrs:{"mode":"passive","name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"refFocus",attrs:{"id":"username","name":"username","state":errors.length > 0 ? false:null,"placeholder":"John Doe"},model:{value:(_vm.form.userName),callback:function ($$v) {_vm.$set(_vm.form, "userName", $$v)},expression:"form.userName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":this.$t('pages.signup.singular.email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"john@example.com"},model:{value:(_vm.form.userEmail),callback:function ($$v) {_vm.$set(_vm.form, "userEmail", $$v)},expression:"form.userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":this.$t('pages.signup.singular.password'),"label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":this.$t('pages.signup.singular.passwordConfirmation'),"label-for":"password-confirmation"}},[_c('validation-provider',{attrs:{"name":"confirm","rules":"required|confirm:@password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password-confirmation","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.isAccepted),callback:function ($$v) {_vm.isAccepted=$$v},expression:"isAccepted"}},[_vm._v(" "+_vm._s(this.$t('pages.signup.iAgree'))+" "),_c('b-link',[_vm._v(_vm._s(this.$t('pages.signup.terms')))])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.termsValidationMessages))])],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_vm._e(),_vm._v(" "+_vm._s(this.$t('pages.signup.actions.signUp'))+" ")],1)],1)],1),_c('p',{staticClass:"text-center mt-1 mb-0"},[_c('span',[_vm._v(_vm._s(this.$t('pages.signup.alreadyHaveAccount')))]),_c('br'),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('span',[_vm._v(" "+_vm._s(this.$t('pages.signup.actions.signInInstead')))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }